import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Box, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { base_url, base_url_long } from "../../index";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import ReactPlayer from 'react-player';

const VideoMarking = () => {
  const [videoData, setVideoData] = useState(null);
  const [markersData, setMarkersData] = useState([]);
  const [formValues, setFormValues] = useState({
    firstTime: '',
    secondTime: '',
    firstExtraTime: '',
    secondExtraTime: ''
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const playerRef = useRef(null);
  const [seekTimeout, setSeekTimeout] = useState(null);
  const [allMarkersHaveTimestamps, setAllMarkersHaveTimestamps] = useState(false);
  const [missingMarkers, setMissingMarkers] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // success | error
  const [snackbarMessage, setSnackbarMessage] = useState('');



  let { id } = useParams();

  const fetchVideo = async () => {
    try {
      const response = await fetch(`${base_url}match/${id}/`);
      const data = await response.json();
      setVideoData(data.videos[0]);
    } catch (error) {
      console.error("Error fetching video:", error);
    }
  };

  const fetchMarkers = async () => {
  try {
    const response = await fetch(`${base_url_long}registrator/match_markers_fast/${id}`);
    const data = await response.json();

    const filteredMarkers = data.markers.filter(marker =>
      [1, 75, 81, 85].includes(marker.action.id)
    );

    setMarkersData(filteredMarkers);

    // Проверяем, какие маркеры имеют timestamp === null или undefined
    const missing = filteredMarkers.filter(marker => marker.timing === null || marker.timing === undefined);

    setMissingMarkers(missing);

    if (missing.length > 0) {
      setSnackbarSeverity('error');
      setSnackbarMessage(`Missing timestamps: ${missing.map(m => m.action.title).join(', ')}`);
    } else {
      setSnackbarSeverity('success');
      setSnackbarMessage('All markers have timestamps!');
    }

    setShowSnackbar(true);
  } catch (error) {
    console.error('Error fetching markers:', error);
  }
};

  useEffect(() => {
    fetchVideo();
    fetchMarkers();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    const timeInSeconds = isNaN(value) ? convertToSeconds(value) : parseFloat(value);
    if (seekTimeout) {
      clearTimeout(seekTimeout);
    }

    const newTimeout = setTimeout(() => {
      let seekTime = timeInSeconds;

      if (name === 'firstTime') {
        seekTime += 45 * 60;
      } else if (name === 'secondTime') {
        seekTime += 90 * 60;
      } else if (name === 'firstExtraTime') {
        seekTime += 105 * 60;
      } else if (name === 'secondExtraTime') {
        seekTime += 120 * 60;
      }

      seekToTime(seekTime);
    }, 3000);

    setSeekTimeout(newTimeout);
  };

  const convertToSeconds = (time) => {
    if (!time) return 0;

    const parts = time.split(':').reverse();
    let seconds = 0;

    if (parts[0]) seconds += parseInt(parts[0]); // Секунды
    if (parts[1]) seconds += parseInt(parts[1]) * 60; // Минуты
    if (parts[2]) seconds += parseInt(parts[2]) * 3600; // Часы

    return seconds;
  };

  const seekToTime = (seconds) => {
    if (playerRef.current) {
      playerRef.current.seekTo(seconds, 'seconds');
      console.log(`Seeking to ${seconds} seconds`);
    }
  };

  const formatTime = (seconds) => {
  if (!seconds || isNaN(seconds)) return 'Нет данных';

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  return [hours, minutes, secs]
    .map(unit => String(unit).padStart(2, '0')) // Добавляем ведущие нули
    .join(':');
};

  const handleSubmit = async (event) => {
    event.preventDefault();

    const markerUpdates = [
      { actionId: 1, time: convertToSeconds(formValues.firstTime) },
      { actionId: 75, time: convertToSeconds(formValues.secondTime) },
      { actionId: 81, time: convertToSeconds(formValues.firstExtraTime) },
      { actionId: 85, time: convertToSeconds(formValues.secondExtraTime) }
    ];

    const patchRequests = markersData.map(marker => {
      const update = markerUpdates.find(update => update.actionId === marker.action.id);

      if (update && update.time) {
        return fetch(`${base_url_long}registrator/marker/${marker.id}/`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ timing: update.time })
        });
      }

      return null;
    }).filter(Boolean);

    try {
      await Promise.all(patchRequests);
      setSuccessMessage('Markers updated successfully');
      fetchMarkers(); // Перезагружаем маркеры после успешного обновления
    } catch (error) {
      setErrorMessage('Error updating markers');
    }
  };

  return (
    <>
          <Snackbar
      open={showSnackbar}
      autoHideDuration={6000}
      onClose={() => setShowSnackbar(false)}
        >
          <Alert severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

      <Box
  style={{
    marginTop: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '20px' // Промежуток между видео и формой
  }}
>
  {/* Видео */}
  {videoData ? (
    <ReactPlayer
      ref={playerRef}
      url={videoData.link}
      controls
      width="60%"
      height="auto"
    />
  ) : (
    <p>Loading video...</p>
  )}

  {/* Форма (теперь рядом с видео) */}
  <Box style={{ width: '30%' }}>

    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {Object.keys(formValues).map((key) => (
        <TextField
          key={key}
          label={key.replace(/([A-Z])/g, ' $1')}
          name={key}
          value={formValues[key]}
          onChange={handleInputChange}
          fullWidth
        />
      ))}
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage('')}>
        <Alert severity="success">{successMessage}</Alert>
      </Snackbar>

      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage('')}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </form>
  </Box>
</Box>

      {/* Таблица маркеров */}
      <Box style={{ marginTop: 20, width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Timing (regarding the video)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {markersData.length > 0 ? (
                markersData.map(marker => (
                  <TableRow key={marker.id}>
                    <TableCell>{marker.action.id}</TableCell>
                    <TableCell>{marker.action.title}</TableCell>
                    <TableCell>{formatTime(marker.timing)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="3" align="center"></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>


    </>
  );
};

export default VideoMarking;
